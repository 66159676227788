import React from "react"
import { keyframes } from "@emotion/react"
import { Box } from "../primitives/base"

/*
	This component creates opacity transition based on the shouldChange prop changing.
 */

const keys = keyframes({
	"0%": {
		transform: "skewX(-10deg) scaleX(0)",
		transformOrigin: "left center",
		marginLeft: "-20vw",
	},
	"30%": {
		transform: "skewX(-10deg) scaleX(1)",
		transformOrigin: "left center",
	},
	"30.5%": {
		transform: "skewX(-10deg) scaleX(1)",
		transformOrigin: "right center",
	},
	"67%": {
		opacity: "1",
	},
	"99%": {
		transform: "skewX(-10deg) scaleX(0)",
		transformOrigin: "right center",
		marginLeft: "0vw",
		marginRight: "-100vw",
		opacity: "0",
	},
})

interface Props {
	shouldChange: string
	className?: string
}

const FadeTransition: React.FC<Props> = ({
	shouldChange,
	className = "",
	children,
}) => {
	const prevChildren = React.useRef<React.ReactNode>(null)
	const currentChildren = React.useRef<React.ReactNode>(null)
	const currentContainer = React.useRef<HTMLDivElement>(null)

	const [isAnimating, setIsAnimating] = React.useState<boolean>(false)

	const currentKey = React.useRef<string>("")

	if (
		currentKey?.current !== null &&
		currentKey.current !== shouldChange &&
		currentChildren?.current &&
		currentChildren.current !== children
	) {
		currentKey.current = shouldChange
		prevChildren.current = currentChildren.current
		setIsAnimating(true)
		setTimeout(() => setIsAnimating(false), 700)
	} else {
		currentChildren.current = children
		currentKey.current = shouldChange
	}

	return (
		<div
			css={{
				display: "flex",
				width: "100%",
				height: "100%",
				flexGrow: 1,
				position: "relative",
			}}
		>
			<div
				css={{
					display: "flex",
					width: "100%",
					height: "100%",
					transition: "0.4s",
					opacity: isAnimating ? 0 : 1,
					flexGrow: 1,
				}}
				ref={currentContainer}
				className={className}
			>
				{isAnimating ? prevChildren.current : children}
			</div>
			<Box
				key={shouldChange}
				xl={{
					position: "fixed",
					width: "100%",
					height: "100%",
					backgroundColor: "var(--secundary-color)",
					zIndex: "100",
					animationName: keys,
					animationPlayState: isAnimating ? "running" : "paused",
					transform: "skewX(-10deg) scaleX(0)",
					transformOrigin: "left center",
				}}
				sm={{
					animation: "none",
				}}
				style={{
					animationDuration: "0.8s",
					//animationFillMode: "forwards",
					animationIterationCount: isAnimating ? "infinite" : "0",
				}}
			/>
		</div>
	)
}

export default FadeTransition
