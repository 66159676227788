import React from "react"
import { Box } from "./primitives/base"

interface PurpleButtonProps {
	size: "sm" | "md" | "lg" | "xl"
	isGradient?: boolean
	text: string
	className?: string
	children?: React.ReactNode | React.ReactNode[]
}

const PurpleButton = ({
	size = "md",
	isGradient = true,
	text,
	children,
	className,
	...props
}: PurpleButtonProps): React.ReactElement => {
	const sizes = {
		sm: { width: "124px", height: "60px" },
		md: { width: "182px", height: "60px" },
		lg: { width: "252px", height: "70px" },
		xl: { width: "100%", height: "100%" },
	}
	return (
		<Box
			as="button"
			css={{
				transform: "translateZ(0px)",
				boxShadow: "0 0 4px var(--primary-color-variation-30)",
				position: "relative",
				color: "#FFFFFF",
				whiteSpace: "nowrap",
				fontStyle: "normal",
				minWidth: sizes[size]?.width,
				minHeight: sizes[size]?.height,
				maxWidth: "100%",
				fontWeight: "600",
				borderRadius: "8px",
				padding: "12px 24px",
				textAlign: "center",
				lineHeight: "1!important",
				alignItems: "center",
				justifyContent: "center",
				display: "inline-flex",
				background: "var(--primary-color)",
				overflow: "hidden",
				transition: "0.3s",
				"&:hover:not([disabled])": {
					boxShadow: "0 0 7px var(--primary-color-variation-60)",
					background: "var(--primary-color-variation)",
					"& div": {
						transform: "translateX(0)",
						"@media (max-width: 767px)": {
							transform: "translateX(0)",
						},
					},
				},
				"&:disabled": {
					opacity: 0.8,
					cursor: "not-allowed",
				},
				"@media (max-width: 767px)": {
					paddingLeft: "20px",
					paddingRight: "20px",
					paddingTop: "12px",
					paddingBottom: "12px",
					flexShrink: 0,
					minWidth: "fit-content",
					height: "fit-content",
					minHeight: "50px",
					whiteSpace: "nowrap",
					borderRadius: "8px",
				},
			}}
			className={`button-text ${className ? className : ""}`}
			{...props}
		>
			<span
				css={{
					position: "relative",
					zIndex: 2,
				}}
			>
				{text && text}
				{children && children}
			</span>
		</Box>
	)
}

export default PurpleButton
