import AnchorLink from "./anchor-link"
import React from "react"

const IndicatorLink = ({ to, linkPrefix, ...props }) => {
	const [isFocused, setIsFocused] = React.useState(false)

	React.useEffect(() => {
		const int = setInterval(() => {
			let hash
			if (to.includes("#")) {
				const hashIndex = to.lastIndexOf("#")
				hash = to.substring(hashIndex)
			}
			if (window.location.hash === hash) {
				setIsFocused(true)
			} else {
				setIsFocused(false)
			}
		}, 200)

		return () => {
			clearInterval(int)
		}
	}, [])

	if (typeof to === "string" && to.startsWith("http")) {
		return (
			<a
				href={to}
				target="_blank"
				rel="noreferrer"
				css={{
					"&:before": {
						content: '""',
						position: "absolute",
						width: "28px",
						transform: isFocused ? "scaleX(1)" : "scaleX(0)",
						height: "2px",
						bottom: "-5px",
						left: "0",
						backgroundColor: "var(--secundary-color)",
						transformOrigin: "bottom left",
						transition: "transform 0.3s ease-out",
					},
					"&:hover:before": {
						transform: "scaleX(1)",
						transformOrigin: "bottom left",
					},
				}}
				{...props}
			/>
		)
	}

	return (
		<AnchorLink
			to={`${linkPrefix}${to}`}
			css={{
				"&:before": {
					content: '""',
					position: "absolute",
					width: "28px",
					transform: isFocused ? "scaleX(1)" : "scaleX(0)",
					height: "2px",
					bottom: "-5px",
					left: "0",
					backgroundColor: "var(--secundary-color)",
					transformOrigin: "bottom left",
					transition: "transform 0.3s ease-out",
				},
				"&:hover:before": {
					transform: "scaleX(1)",
					transformOrigin: "bottom left",
				},
			}}
			{...props}
		/>
	)
}

export default IndicatorLink
