import React from "react"
import TrapofitLogo from "./trapofit-logo"
import { graphql, Link, useStaticQuery } from "gatsby"
import MobileNavOpen from "./mobile-nav-open"
import { Box } from "./primitives/base"

const BlogHeader = () => {
	const categoryData = useStaticQuery(graphql`
		{
			allCategory {
				edges {
					node {
						id
						title
						slug
						post {
							id
							title
							short_description
						}
					}
				}
			}
			country(countryId: { eq: "1" }) {
				phone
				subdomain
				mail
				facebook_link
			}
		}
	`)

	return (
		<div
			css={{
				width: "100%",
				display: "flex",
				position: "relative",
				alignItems: "flex-start",
				flexDirection: "column",
				boxShadow: "0 0 14px #0006",
				position: "sticky",
				top: "0",
				zIndex: "100",
			}}
		>
			<div
				css={{
					width: "100%",
					display: "flex",
					alignItems: "flex-start",
					backgroundColor: "#000000",
				}}
			>
				<Box
					className="container-xl"
					xl={{
						width: "100%",
						display: "flex",
						alignItems: "flex-start",
						margin: "auto",
					}}
					md={{
						display: "none",
					}}
				>
					<div
						css={{
							width: "100%",
							display: "flex",
							alignItems: "center",
							paddingTop: "44px",
							paddingBottom: "44px",
							height: "148px",
							justifyContent: "space-between",
						}}
					>
						<Link
							to="/de/"
							css={{
								position: "absolute",
							}}
						>
							<TrapofitLogo />
						</Link>
						<div
							className="container-md"
							css={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-end",
							}}
						>
							<div
								css={{
									width: "100%",
									display: "flex",
									maxWidth: "780px",
									alignItems: "center",
									justifyContent: "flex-end",
									"@media (max-width: 1041px)": {
										display: "none",
									},
									"&>*:not(style)~*:not(style)": {
										marginLeft: "30px",
									},
								}}
							>
								{categoryData.allCategory.edges &&
									categoryData.allCategory.edges.map((category) => {
										return (
											<>
												{category?.node?.post.length > 0 && (
													<div key={category?.node?.title}>
														<Link
															to={`/de/blog/kategorie/${category.node.slug}`}
															css={{
																color: "#FFFFFF",
																fontSize: "20px",
																position: "relative",
																paddingBottom: "4px",
																lineHeight: "28px",
																"@media (max-width: 960px)": {
																	fontSize: "16px",
																},
																"&:before": {
																	content: '""',
																	position: "absolute",
																	width: "28px",
																	transform: "scaleX(0)",
																	height: "2px",
																	bottom: "0",
																	left: "0",
																	backgroundColor: "var(--secundary-color)",
																	transformOrigin: "bottom left",
																	transition: "transform 0.3s ease-out",
																},
																"&:hover:before": {
																	transform: "scaleX(1)",
																	transformOrigin: "bottom left",
																},
															}}
														>
															{category.node.title}
														</Link>
													</div>
												)}
											</>
										)
									})}
								<div>
									<Link
										to={`/de/blog/`}
										css={{
											color: "#FFFFFF",
											fontSize: "20px",
											position: "relative",
											paddingBottom: "4px",
											lineHeight: "28px",
											"@media (max-width: 960px)": {
												fontSize: "16px",
											},
											"&:before": {
												content: '""',
												position: "absolute",
												width: "28px",
												transform: "scaleX(0)",
												height: "2px",
												bottom: "0",
												left: "0",
												backgroundColor: "var(--secundary-color)",
												transformOrigin: "bottom left",
												transition: "transform 0.3s ease-out",
											},
											"&:hover:before": {
												transform: "scaleX(1)",
												transformOrigin: "bottom left",
											},
										}}
									>
										Alle Blogbeiträge
									</Link>
								</div>
							</div>
						</div>
					</div>
				</Box>
				<Box
					xl={{
						display: "none",
					}}
					md={{
						padding: "0 0 0 24px",
						display: "block",
						width: "100%",
					}}
				>
					<MobileNavOpen
						mobileNavContent={{
							accordionLink: {
								title: "Kategorie",
								content: [
									...categoryData?.allCategory?.edges.map((item) => ({
										title: item.node.title,
										link: `/de/blog/${item.node.slug}`,
									})),
								],
							},
							normalLink: [
								{
									title: "Filialfinder",
									link: "/de/#filialfinder",
								},
								{
									title: "Kontakt",
									link: `/de/#kontakt`,
								},
							],
						}}
						phoneNumber={categoryData?.country?.phone}
						LogoType={`country`}
						googleLink={categoryData?.country?.google_link}
						facebookLink={categoryData?.country?.facebook_link}
					/>
				</Box>
			</div>
		</div>
	)
}
export default BlogHeader
