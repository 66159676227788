import React from "react"
import TrapofitLogo from "../components/trapofit-logo"
import ContactPhoneLogo from "../images/contactPhone.svg"
import { Link } from "gatsby"
import MobileNavOpen from "./mobile-nav-open"
import PurpleButton from "./purple-button"
import IndicatorLink from "./indicator-link"
import { Box } from "./primitives/base"
import { RemoveScroll } from "react-remove-scroll"

import menuLinks from "../constants/menu"
import { EnvelopeClosedIcon } from "@radix-ui/react-icons"

const StickyHeader = ({ data }) => {
	const results = data?.country || data?.franchise

	const isFranchise = !!data?.franchise

	const linkPrefix = isFranchise ? `/de/${data.franchise.slug}` : `/de/`

	const linksForPage = menuLinks[data?.country ? "country" : "franchise"]

	return (
		<Box
			as="nav"
			xl={{
				width: "100%",
				display: "flex",
				alignItems: "flex-start",
				marginBottom: "80px",
				backgroundColor: "#000000",
				position: "fixed",
				top: "0",
				zIndex: "15",
				boxShadow: "0 0 14px #0006",
			}}
		>
			<div
				className={RemoveScroll.classNames.fullWidth}
				css={{ width: "100%" }}
			>
				<Box
					className="container-xl"
					xl={{
						width: "100%",
						// height: "116px",
						display: "flex",
						alignItems: "center",
						marginLeft: "auto",
						marginRight: "auto",
						paddingTop: "44px",
						paddingBottom: "44px",
						justifyContent: "space-between",
						backgroundColor: "#000000",
					}}
					md={{
						paddingTop: "32px",
						paddingBottom: "32px",
						display: "none",
					}}
				>
					<Link to="/de/">
						<TrapofitLogo isFranchise={!data?.country} />
					</Link>
					<Box
						xl={{
							paddingLeft: "50px",
							width: "100%",
							maxWidth: "992px",
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
						md={{
							display: "none",
						}}
					>
						{linksForPage.desktop.map((item) => {
							return (
								<span
									key={item?.title}
									className="button-text"
									css={{
										color: "white",
										position: "relative",
										whiteSpace: "nowrap",
									}}
								>
									<IndicatorLink linkPrefix={linkPrefix} to={item.link}>
										{item.title}
									</IndicatorLink>
								</span>
							)
						})}
						<div
							css={{
								flex: "0 0 auto",
								display: "flex",
								alignItems: "flex-start",
								height: "60px",
							}}
						>
							{/*}
							<PurpleButton
								isGradient
								text="Termin buchen"
								onClick={() => {
									window.dataLayer.push({ event: `booking_click_header` })
									window.location.href = `https://${
										process.env.GATSBY_PUBLIC_NEXT_URL
									}/book/${results?.franchiseId ? results.franchiseId : ""}`
								}}
								css={{
									display: "flex",
									alignItems: "center",
									marginRight: "8px",
									justifyContent: "center",
									backgroundColor: "var(--primary-color)",
									"@media (max-width: 612px)": {
										display: "none",
									},
								}}
							/>
							{*/}
							{results?.phone && (
								<PurpleButton
									isGradient
									css={{
										minWidth: "60px",
										maxWidth: "60px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										borderRadius: "8px",
									}}
									onClick={() => {
										window.dataLayer.push({ event: `phone_click_header` })
										window.location.href = `tel:${results?.phone.replace(
											/\s+/g,
											""
										)}`
									}}
								>
									<ContactPhoneLogo
										fill="white"
										css={{
											width: "24px",
											height: "24px",
											objectFit: "cover",
											marginRight: "0px",
										}}
									/>
								</PurpleButton>
							)}
							{results?.mail && (
								<PurpleButton
									isGradient
									css={{
										minWidth: "60px",
										maxWidth: "60px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										borderRadius: "8px",
										marginLeft: "16px",
									}}
									onClick={() => {
										window.dataLayer.push({ event: `mail_click_header` })
										window.location.href = `mailto:${results?.mail}`
									}}
								>
									<EnvelopeClosedIcon
										fill="white"
										css={{
											width: "30px",
											height: "30px",
											objectFit: "cover",
											marginRight: "0px",
										}}
									/>
								</PurpleButton>
							)}
						</div>
					</Box>
				</Box>
				<Box
					xl={{
						width: "100%",
						height: "80px",
						display: "none",
						alignItems: "flex-start",
					}}
					md={{
						height: "auto",
						display: "flex",
						alignItems: "center",
						padding: "0 0 0 24px",
						justifyContent: "space-between",
					}}
				>
					<MobileNavOpen
						mobileNavContent={linksForPage.mobile}
						phoneNumber={results?.phone}
						LogoType={`${data?.franchise ? `franchise` : `country`}`}
						googleLink={results?.google_link}
						facebookLink={results?.facebook_link}
					/>
				</Box>
			</div>
		</Box>
	)
}

export default StickyHeader
