import React from "react"
import JumpToTopArrow from "../images/jumpToTopArrow.svg"
import { RemoveScroll } from "react-remove-scroll"

const JumpToTop = () => {
	const handleClick = () => {
		typeof window !== "undefined" &&
			window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
	}

	return (
		<div
			className={RemoveScroll.classNames.zeroRight}
			css={{
				position: "fixed",
				zIndex: 100,
				bottom: "40px",
				right: 0,
				pointerEvents: "none",
			}}
		>
			<button
				css={{
					pointerEvents: "auto",
					marginRight: "40px",
					display: "flex",
					justifyContent: "center",
					boxShadow: "0 0 8px #0007",
					alignItems: "center",
					zIndex: 100,
					width: "60px",
					height: "60px",
					background: "var(--primary-color)",
					borderRadius: "50%",
					transition: "0.3s",
					"&:hover": {
						background: "var(--primary-color-variation)",
					},
					"@media (max-width: 767px)": {
						display: "none",
					},
				}}
				onClick={handleClick}
			>
				<span
					css={{
						display: "inline-block",
						width: "26px",
						height: "auto",
					}}
				>
					<JumpToTopArrow width="100%" height="100%" />
				</span>
			</button>
		</div>
	)
}

export default JumpToTop
