exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agb-tsx": () => import("./../../../src/pages/agb.tsx" /* webpackChunkName: "component---src-pages-agb-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-schadensbericht-tsx": () => import("./../../../src/pages/schadensbericht.tsx" /* webpackChunkName: "component---src-pages-schadensbericht-tsx" */),
  "component---src-pages-teilnahme-tsx": () => import("./../../../src/pages/teilnahme.tsx" /* webpackChunkName: "component---src-pages-teilnahme-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blog-category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-tag-tsx": () => import("./../../../src/templates/blog-tag.tsx" /* webpackChunkName: "component---src-templates-blog-tag-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-country-tsx": () => import("./../../../src/templates/country.tsx" /* webpackChunkName: "component---src-templates-country-tsx" */),
  "component---src-templates-franchise-tsx": () => import("./../../../src/templates/franchise.tsx" /* webpackChunkName: "component---src-templates-franchise-tsx" */),
  "component---src-templates-single-blog-tsx": () => import("./../../../src/templates/single-blog.tsx" /* webpackChunkName: "component---src-templates-single-blog-tsx" */)
}

