// @ts-nocheck
import React, { useEffect } from "react"
import { useLocation, Link } from "@reach/router"
import { getPositionOfElement } from "../utils/get-position-of-element"

/*
 *
 * This set of components creates scrollable sections, for single-page scroller sites, and works with SSR
 *
 *  */

interface ScrollableSectionProps extends React.HTMLProps<HTMLDivElement> {
	href: string
}
const win = typeof window === "undefined" ? null : window
const getScrollY = (): number => {
	return (win as Window).scrollY !== void 0
		? (win as Window).scrollY
		: (win as Window).pageYOffset === void 0
		? 0
		: (win as Window).pageYOffset
}

const Ctx = React.createContext({})

export const PageListenerProvider = (props) => {
	const registered = React.useRef([])
	const lastInViewId = React.useRef("")
	const alsoSubbed = React.useRef([])

	React.useEffect(() => {
		const timer = setInterval(() => {
			const y = getScrollY()
			let found = registered.current
				.filter((x) => x.pageTop <= y)
				.find((x) => x.pageBottom > y)
			if (!found) {
				const lastElOnPage = registered.current
					.filter((x) => x.pageTop < y)
					.sort((x, y) => y.pageBottom - x.pageBottom)
					.find((x) => x)
				if (lastElOnPage) {
					found = lastElOnPage
				}
			}

			if (found && lastInViewId.current !== found.id) {
				if (lastInViewId) {
					const last = registered.current.find((x) => x.id === lastInViewId)
					if (last) {
						if (last.onExit) {
							last.onExit()
						}
						const otherOldSubs = alsoSubbed.current.filter(
							(x) => x.id === last.id
						)
						otherOldSubs.forEach(({ onExit }) => {
							if (onExit) {
								onExit()
							}
						})
					}
				}
				if (found.onEnter) {
					found.onEnter()
					lastInViewId.current = found.id
				}
				const otherNewSubs = alsoSubbed.current.filter((x) => x.id === found.id)
				otherNewSubs.forEach(({ onEnter }) => {
					if (onEnter) {
						onEnter()
					}
				})
			}
		}, 1000)

		return () => {
			clearInterval(timer)
		}
	}, [])

	return (
		<Ctx.Provider
			value={{
				register: ({ id, ref, onEnter, onExit }) => {
					if (registered?.current.find((x) => x.id === id)) return
					const { pageTop, pageBottom } = getPositionOfElement(ref)
					registered.current = [
						...registered.current,
						{ id, pageTop, pageBottom, onEnter, onExit },
					]
				},
				deregister: ({ id }) => {
					registered.current = [
						...registered.current.filter((x) => x.id !== id),
					]
				},
				subscribeToId: ({ id, onEnter, onExit }) => {
					alsoSubbed.current = [...alsoSubbed.current, { id, onEnter, onExit }]
				},
				unsubscribeToId: ({ id }) => {
					alsoSubbed.current = [
						...alsoSubbed.current.filter((x) => x.id !== id),
					]
				},
			}}
			{...props}
		/>
	)
}

export const usePageListener = ({ id, defaultInView = false }) => {
	const { subscribeToId, unsubscribeToId } = React.useContext(Ctx)
	const [inView, setInView] = React.useState(defaultInView)

	const onEnter = () => {
		setInView(true)
	}

	const onExit = () => {
		setInView(false)
	}

	React.useEffect(() => {
		subscribeToId({ id, onEnter, onExit })
		return () => {
			unsubscribeToId({ id })
		}
	}, [])

	return inView
}

export default function ScrollableSection({
	href,
	...props
}: ScrollableSectionProps) {
	const { register, deregister } = React.useContext(Ctx)

	const findRef = React.useRef<HTMLDivElement>(null)

	const hash = useHash(href)
	const normalized = useLink(href)

	const onEnter = () => {
		window.history.replaceState({}, "", normalized)
	}

	useEffect(() => {
		if (findRef?.current) {
			register({ id: href, ref: findRef, onEnter })
		}
		return () => {
			deregister({ id: href })
		}
	}, [findRef?.current])

	return (
		<div ref={findRef}>
			<span id={hash.replace("#", "").replace("/", "")} />
			<div {...props} />
		</div>
	)
}

interface ScrollableLinkProps {
	href: string
	onClick?: () => void
	showActive?: boolean
	[key: string]: any
}

export const ScrollableLink = ({
	href,
	onClick,
	showActive,
	...props
}: ScrollableLinkProps) => {
	const normalized = useLink(href)
	const hash = useHash(href)

	const inView = usePageListener({ id: href })

	const handleClick = () => {
		const el = document.getElementById(hash.replace("#", ""))
		if (el) {
			const dims = getPositionOfElement({ current: el })
			if (dims) {
				window.scrollTo({
					left: window.scrollX,
					top: dims?.pageTop - 100,
					behavior: "smooth",
				})
			}
		}
		if (onClick && typeof onClick === "function") {
			onClick()
		}
	}

	if (showActive) {
		return (
			<Link
				to={normalized}
				onClick={handleClick}
				{...props}
				style={{
					transform: inView ? "scaleX(1)" : "scaleX(0)",
				}}
				css={{
					"&:before": {
						content: '""',
						position: "absolute",
						width: "28px",
						height: "2px",
						bottom: "0",
						left: "0",
						backgroundColor: "var(--secundary-color)",
						transformOrigin: "bottom left",
						transition: "transform 0.3s ease-out",
					},
					"&:hover:before": {
						transform: "scaleX(1)",
						transformOrigin: "bottom left",
					},
				}}
			/>
		)
	}

	return <Link to={normalized} onClick={handleClick} {...props} />
}

function useLink(link: string): string {
	const { pathname } = useLocation()
	const normalized = useHash(link)
	return normalizeLink(pathname) + normalized
}

function useHash(link: string): string {
	link =
		link.endsWith("/") && link.length > 1
			? link.substring(0, link.length - 1)
			: link
	link = link.startsWith("/") ? link.substring(1, link.length) : link
	link =
		link.startsWith("#") && link.length > 0
			? "/#" + link.substring(1, link.length)
			: "/#" + link
	return link
}

function normalizeLink(link: string): string {
	link = "/" + link
	link =
		link.endsWith("/") && link.length > 1
			? link.substring(0, link.length - 1)
			: link
	link = link.startsWith("/") ? link.substring(1, link.length) : link
	link =
		link.startsWith("//") && link.length > 0
			? link.substring(1, link.length)
			: link
	return link
}
