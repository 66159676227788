
import {Link} from "gatsby";

const AnchorLink = ({ to, className, style, ...props }) => {
	let withoutHash = to;
	let hash;
	if (to.includes('#')) {
		const hashIndex = to.lastIndexOf('#');
		withoutHash = to.substring(0, hashIndex);
		hash = to.substring(hashIndex);
	}
	return (
		<Link
			to={withoutHash}
			onClick={() => {
				if (hash) {
					window.gatsby_router_hash = hash;
				}
			}}
			className={className}
			style={style}
			{...props}
		/>
	);
};

export default AnchorLink
