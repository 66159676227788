import React from "react"

export const Box = React.forwardRef(
	(
		{
			css = {},
			as = "div",
			base = {},
			sm = {},
			md = {},
			lg = {},
			xl = {},
			...props
		},
		ref
	) => {
		const Component = as
		return (
			<Component
				ref={ref}
				css={{
					...css,
					...xl,
					"@media (max-width: 1440px)": {
						...lg,
					},
					"@media (max-width: 1222px)": {
						...md,
					},
					"@media (max-width: 792px)": {
						...sm,
					},
					"@media (max-width: 612px)": {
						...base,
					},
				}}
				{...props}
			/>
		)
	}
)

export const Flex = ({ xl = {}, ...props }) => {
	return (
		<Box
			{...props}
			xl={{
				...xl,
				display: "flex",
			}}
		/>
	)
}
