import "./reset.css"
import "./fonts.css"
import React from "react"
import { PageProps } from "gatsby"
import Footer, { FranchiseFooter } from "../components/footer"
import "wysiwyg.css/wysiwyg.css"
import "keen-slider/keen-slider.min.css"
import "focus-visible/dist/focus-visible"
import { SkipNavContent, SkipNavLink } from "@reach/skip-nav"
import "@reach/skip-nav/styles.css"
import FadeTransition from "../components/transitions/fade-transition"
import BlogHeader from "../components/blog-header"
import StickyHeader from "../components/sticky-header"
import Provider from "../components/primitives/provider"
import JumpToTop from "../components/jump-to-top"

import {PageListenerProvider} from "../components/dynamic-anchors";

const Layout = ({
	children,
	location,
	data,
}: PageProps): React.ReactElement => {

	return (
		<PageListenerProvider>
			<Provider>
				<SkipNavLink contentId="skip-nav" />
				<JumpToTop />
				<div
					css={{
						display: "flex",
						minHeight: "100vh",
						flexDirection: "column",
						backgroundColor: "#0F0F0F",
					}}
				>
					{location.pathname.match(/^(\/[\w-]+\/blog)/) ? (
						<BlogHeader data={data} location={location} />
					) : (
						<StickyHeader data={data} />
					)}
					<FadeTransition
						css={{ flexDirection: "column" }}
						shouldChange={location.pathname.replaceAll("/", "")}
					>
						<SkipNavContent id="skip-nav" as="main">
							{children}
						</SkipNavContent>
						{data?.franchise ? (
							<FranchiseFooter data={data} />
						) : (
							<Footer data={data} />
						)}
					</FadeTransition>
				</div>
			</Provider>
		</PageListenerProvider>
	)
}

export default Layout
